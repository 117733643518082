import React from "react";
import { Modal } from "react-bootstrap";
import classes from "./CreateSource.module.css";
import { Button, Form, Spinner } from "react-bootstrap";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ErrorHandling from "./ErrorHandling";
import cancelIcon from "../../../asset/Images/cancel icon.svg";

const SourcingFooter = (props) => {
  const {
    show,
    status,
    access,
    submitLoading,
    handleHideSourcingModal,
    UpdateSourcingRequest,
    modalSuccessMessage,
    modalErrorMessage,
  } = props;


  return (
    <>
      {status === "completed" && access && (
        <Modal
          centered
          contentClassName={classes.srStatusModalContent}
          show={show}
          onHide={handleHideSourcingModal}
          dialogClassName={`${classes.srStatusModal}`}
        >
          <div className="wrapper_modal">
            <Modal.Body className={classes.srStatusModalBody}>
              {modalErrorMessage ? (
                <ErrorHandling message={modalErrorMessage} type={"ErrorMessage"} />
              ) : null}
              {modalSuccessMessage ? (
                <ErrorHandling message={modalSuccessMessage} type={"SuccessMessage"} />
              ) : null}
              <div className={classes.srModalHeader}>
                <h4 className={`${classes.srStatusModalTitle} mb-0`}>
                  Change status of this SR from Completed to Pending
                </h4>
                <Button
                  onClick={handleHideSourcingModal}
                  className={classes.srStatusModalCancel}
                >
                  <img
                    src={cancelIcon}
                    alt="cross"
                    width="32px"
                    height="32px"
                  />
                </Button>
              </div>
              <div className={classes.srStatusModalBtnWrapper}>
                <Button
                  className={`${classes.submit} ${classes.cancel}`}
                  onClick={handleHideSourcingModal}
                >
                  No
                </Button>
                <Button
                  className={`${classes.submit} ${classes.save} m-0`}
                  id="reopen-sourcing"
                  type="submit"
                  onClick={UpdateSourcingRequest}
                  disabled={submitLoading}
                >
                  {submitLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    "Yes"
                  )}
                </Button>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      )}
    </>
  );
};
export default SourcingFooter;
