import React, { useState, useEffect } from "react";

import paginationLeftBtn from "../../../asset/Images/paginationLeftBtn.svg";
import paginationRightBtn from "../../../asset/Images/paginationRightBtn.svg";
import dottedLinePagi from "../../../asset/Images/dottedLinePagi.svg";
import tooltipIconDown from "../../../asset/Images/tooltipIconDown.svg";

const Pagination = (props) => {
  const { searchData, setSearchData, handleSearchMultipleProduct } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(searchData.length);
    let activeIndex = searchData.filter(({active})=> active);
    if(activeIndex && activeIndex.length){
      setCurrentPage(activeIndex[0].index+1);
    }
  }, [searchData]);
  
  const handlePageClick = (e,page) => {
    handleSearchMultipleProduct(e,"fixed",page);
  };

  const handlePrevious = (e) => {
    handleSearchMultipleProduct(e,"previous");
  };

  const handleNext = (e) => {
    handleSearchMultipleProduct(e,"next");
  };

  const renderPaginationItems = () => {
    const pages = [];
    if (totalPages <= 5) {
      // Show all pages if total pages are 5 or less
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <li key={i}>
            <button
              className={`paginationItem ${currentPage === i ? "active" : ""}`}
              type="button"
              onClick={(e) => handlePageClick(e,i)}
            >
              {i}
            </button>
              <div className="paginationItemMsgCnt">
                <p className="paginationItemMsg">
                  {searchData[i-1].product ? searchData[i-1].product :searchData[i-1].category}
                </p>
              </div>
              <div className="tooltipIcon">
                <img
                  src={tooltipIconDown}
                  alt="arrow icon"
                  width="16px"
                  height="9px"
                />
              </div>
          </li>
        );
      }
    } else {
      // Show the first, last, current, and neighbors, with dots for skipped pages
      pages.push(
        <li key={1}>
          <button
            className={`paginationItem ${currentPage === 1 ? "active" : ""}`}
            type="button"
            onClick={(e) => handlePageClick(e,1)}
          >
            1
          </button>
          <div className="paginationItemMsgCnt">
                <p className="paginationItemMsg">
                  {searchData[0].product ? searchData[0].product : searchData[0].category}
                </p>
              </div>
              <div className="tooltipIcon">
                <img
                  src={tooltipIconDown}
                  alt="arrow icon"
                  width="16px"
                  height="9px"
                />
              </div>
        </li>
      );

      if (currentPage > 3) {
        pages.push(
          <li key="dots-left">
            <button className="paginationItem" type="button">
              <img
                src={dottedLinePagi}
                alt="dotted line"
                width="14px"
                height="2px"
              />
            </button>
          </li>
        );
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <li key={i}>
            <button
              className={`paginationItem ${currentPage === i ? "active" : ""}`}
              type="button"
              onClick={(e) => handlePageClick(e,i)}
            >
              {i}
            </button>
            <div className="paginationItemMsgCnt">
                <p className="paginationItemMsg">
                  {searchData[i-1].product ? searchData[i-1].product : searchData[i-1].category}
                </p>
              </div>
              <div className="tooltipIcon">
                <img
                  src={tooltipIconDown}
                  alt="arrow icon"
                  width="16px"
                  height="9px"
                />
              </div>
          </li>
        );
      }

      if (currentPage < totalPages - 2) {
        pages.push(
          <li key="dots-right">
            <button className="paginationItem" type="button">
              <img
                src={dottedLinePagi}
                alt="dotted line"
                width="14px"
                height="2px"
              />
            </button>
          </li>
        );
      }

      pages.push(
        <li key={totalPages}>
          <button
            className={`paginationItem ${currentPage === totalPages ? "active" : ""}`}
            type="button"
            onClick={(e) => handlePageClick(e,totalPages)}
          >
            {totalPages}
          </button>
          <div className="paginationItemMsgCnt">
                <p className="paginationItemMsg">
                  {searchData[totalPages-1].product ? searchData[totalPages-1].product : searchData[totalPages-1].category}
                </p>
              </div>
              <div className="tooltipIcon">
                <img
                  src={tooltipIconDown}
                  alt="arrow icon"
                  width="16px"
                  height="9px"
                />
              </div>
        </li>
      );
    }

    return pages;
  };

  return (
    <>
      <div className="paginationCnt">
        <button
          className="paginationLeftBtn"
          type="button"
          onClick={handlePrevious}
          disabled={currentPage === 1}
        >
          <img
            src={paginationLeftBtn}
            alt="leftArrow"
            width="32px"
            height="32px"
          />
        </button>
        <ul className="paginationNumberCnt">{renderPaginationItems()}</ul>
        <button
          className="paginationRightBtn"
          type="button"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          <img
            src={paginationRightBtn}
            alt="rightArrow"
            width="32px"
            height="32px"
          />
        </button>
      </div>
    </>
  );
};

export default Pagination;
