import React from "react";
import { Spinner } from "react-bootstrap";
import ErrorHandling from "./ErrorHandling";

const SingleSearch = (props) => {
  const {
    productSingleSearchValue,
    brandSingleSearchValue,
    categorySingleSearchValue,
    categorySuggestionsSingleSearch,
    brandSuggestionsSingleSearch,
    submitLoadingSingleSearch,
    errorMessageSingleSearch,
    showCategorySuggestions,
    showBrandSuggestions,
    getAddNewBrandSingleSearch,
    getAddNewCategorySingleSearch,
    handleChangeSingleSearch,
    handleSearch,
    stateData,
    setStateData,
  } = props;

  return (
    <>
      {errorMessageSingleSearch ? (
        <ErrorHandling
          message={errorMessageSingleSearch}
          type={"ErrorMessage"}
        />
      ) : null}
      <div>
        <h5 className="advanceSearchTilte">Single Product Search</h5>
        <form onSubmit={handleSearch}>
          <div className="advanceSearchFormCnt">
            <div className="advanceSearchInputCnt">
              <label className="advanceSearchLabel">Product</label>
              <input
                type="text"
                name="product"
                value={productSingleSearchValue}
                onChange={handleChangeSingleSearch}
                className="advanceSearchInput"
              />
            </div>

            <div className="advanceSearchInputCnt">
              <label className="advanceSearchLabel">Category</label>
              <input
                type="text"
                name="category"
                value={categorySingleSearchValue}
                onChange={handleChangeSingleSearch}
                className="advanceSearchInput"
              />
              {showCategorySuggestions &&
                categorySuggestionsSingleSearch.length > 0 && (
                  <div className="suggestions">
                    <ul>
                      {categorySuggestionsSingleSearch.map(
                        (suggestion, index) => (
                          <li
                            key={index}
                            onClick={() =>
                              getAddNewCategorySingleSearch(suggestion)
                            }
                          >
                            {suggestion.label}
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
            </div>

            <div className="advanceSearchInputCnt">
              <label className="advanceSearchLabel">Brand</label>
              <input
                type="text"
                name="brand"
                value={brandSingleSearchValue}
                onChange={handleChangeSingleSearch}
                className="advanceSearchInput"
              />
              {showBrandSuggestions &&
                brandSuggestionsSingleSearch.length > 0 && (
                  <div className="suggestions">
                    <ul>
                      {brandSuggestionsSingleSearch.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={() => getAddNewBrandSingleSearch(suggestion)}
                        >
                          {suggestion.label}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </div>
          </div>

          <div className="searchFormBtnWrapper">
            <button
              type="submit"
              className={`advanceSearchFormBtn 
            ${
              !productSingleSearchValue &&
              !brandSingleSearchValue &&
              !categorySingleSearchValue
                ? "cursorNotAllowed"
                : ""
            }`}
              disabled={
                !productSingleSearchValue &&
                !brandSingleSearchValue &&
                !categorySingleSearchValue
              }
            >
              {submitLoadingSingleSearch ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Search"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SingleSearch;
