import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import crossBlack from "../../../asset/Images/crossBlack.svg";
import downArrowGrey from "../../../asset/Images/downArrowGrey.svg";
import SearchResultRow from "./SearchResultRow";
import ErrorHandling from "./ErrorHandling";

const MultipleProductSearch = ({
  showMultipleSearchResultModal,
  showAdvanceSearchResultModal,
  multipleSearchValue,
  multipleSearchLoading,
  // multiple search data state start here
  rowsMultipleSearch,
  suggestionsBrand,
  showSuggestionsBrand,
  focusedIndexBrand,
  suggestionsCategory,
  showSuggestionsCategory,
  focusedIndexCategory,
  submitLoadingMultipleSearch,
  errorMessageMultipleSearch,
  multipleSearchFormData,
  uploadSearchExcelData,
  // multiple search data state ends here
  handleCloseMultipleSearchResultModal,
  handleOpenCancelSRModal,
  handleOpenAdvanceSearchResultModal,
  // multiple state data start here
  editMultipleProductSearchData,
  handleInputChangeBrand,
  handleSuggestionClickBrand,
  clearValueBrand,
  handleInputChangeCategory,
  handleSuggestionClickCategory,
  clearValueCategory,
  handleSearchMultipleProduct,
  // multiple state data ends here
  handleSearchExcelProduct,
  // excel state data ends here
}) => {
  return (
    <>
      <Modal
        show={showMultipleSearchResultModal}
        className="advanceSearchModal modalHeightAuto textAreaModal"
      >
        {errorMessageMultipleSearch ? (
          <ErrorHandling
            message={errorMessageMultipleSearch}
            type={"ErrorMessage"}
          />
        ) : null}
        <Modal.Header className="advanceSearchHeader">
          <Modal.Title>Multiple Product Search</Modal.Title>
          <button
            className="searchModalCloseBtn"
            onClick={handleOpenCancelSRModal}
          >
            <img src={crossBlack} alt="cross icon" width="16px" height="16px" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="multipleResultCnt">
            <table className="searchResulttable">
              <thead>
                <th style={{textAlign:"center"}}>No.</th>
                <th>Product</th>
                <th>
                  <div className="searchResultHeadingItem">
                    <span>Category</span>
                    <img
                      src={downArrowGrey}
                      alt="down arrow"
                      width="12px"
                      height="12px"
                    />
                  </div>
                </th>
                <th>
                  <div className="searchResultHeadingItem">
                    <span>Brand</span>
                    <img
                      src={downArrowGrey}
                      alt="down arrow"
                      width="12px"
                      height="12px"
                    />
                  </div>
                </th>
              </thead>
              <tbody>
                {showMultipleSearchResultModal ? (
                  <SearchResultRow
                    type={0}
                    // multiple search data state start here
                    // uploadSearchExcelData
                    multipleSearchFormData={multipleSearchFormData}
                    rowsMultipleSearch={rowsMultipleSearch}
                    suggestionsBrand={suggestionsBrand}
                    showSuggestionsBrand={showSuggestionsBrand}
                    focusedIndexBrand={focusedIndexBrand}
                    suggestionsCategory={suggestionsCategory}
                    showSuggestionsCategory={showSuggestionsCategory}
                    focusedIndexCategory={focusedIndexCategory}
                    // multiple search data state ends here
                    editMultipleProductSearchData={
                      editMultipleProductSearchData
                    }
                    handleInputChangeBrand={handleInputChangeBrand}
                    handleSuggestionClickBrand={handleSuggestionClickBrand}
                    clearValueBrand={clearValueBrand}
                    handleInputChangeCategory={handleInputChangeCategory}
                    handleSuggestionClickCategory={
                      handleSuggestionClickCategory
                    }
                    clearValueCategory={clearValueCategory}
                  />
                ) : null}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        {showMultipleSearchResultModal ? (
        <Modal.Footer className="searchResultFooterModal">
          <div className="searchResultFooterBtnCnt">
            <button
              className="footerSearchbtn"
              onClick={(e)=>handleSearchMultipleProduct(e, "search")}
            >
              {submitLoadingMultipleSearch ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Search"
              )}
            </button>
          </div>
        </Modal.Footer>
        ):null}
      </Modal>
    </>
  );
};

export default MultipleProductSearch;
