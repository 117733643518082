import React from "react";
import downArrowGrey from "../../../asset/Images/downArrowGrey.svg";
import InfoIcon from "../../../asset/Images/Info-icon.svg";

const SearchResultRow = ({
  type,
  status,
  singleSearchFormData,
  // multiple search data state start here
  rowsMultipleSearch,
  suggestionsBrand,
  showSuggestionsBrand,
  focusedIndexBrand,
  suggestionsCategory,
  showSuggestionsCategory,
  focusedIndexCategory,
  multipleSearchFormData,
  // multiple search data state ends here
  editMultipleProductSearchData,
  handleInputChangeBrand,
  handleSuggestionClickBrand,
  clearValueBrand,
  handleInputChangeCategory,
  handleSuggestionClickCategory,
  clearValueCategory,
  searchedProducts,
  setSearchedProducts,
  currentIndex,
  setCurrentIndex,
  addSelectedProduct,
  unselectedProd,
  products,
  handleSelectedProducts,
  productIdx,
  handleSelectedProductsAddSr,
  productIds,
  // excel data ends here
}) => {
  const getSingleSearchStatus = (status) => {
    switch (status) {
      case 0:
        return "Disabled";
      case 1:
        return "Active";
      case 2:
        return "Draft";
      default:
        return "";
    }
  };

  const getSingleSearchStatusColor = (status) => {
    switch (status) {
      case 0:
        return "#B52A32";
      case 1:
        return "#008D86";
      case 2:
        return "#B56D24";
      default:
        return "";
    }
  };
  switch (type) {
    case 0:
      return (
        <>
          {rowsMultipleSearch.map((row, index) => (
            <tr key={index}>
              <td style={{textAlign:"center"}}>{String(index + 1).padStart(2, "0")}</td>
              {/* Product Description */}
              <td className="resultProductInputWrapper">
                <textarea
                  className="resultProductInput"
                  rows={2}
                  value={row.productDescription || ""}
                  onChange={(e) =>
                    editMultipleProductSearchData(
                      index,
                      "productDescription",
                      e.target.value
                    )
                  }
                />
              </td>
              {/* Product Description ends here */}
              {/* Category start here */}
              <td>
                <div className="searchResultInputWrapper">
                  <textarea
                    className="searchResultInput"
                    rows={1}
                    value={row.productCategory || ""}
                    onChange={(e) =>
                      handleInputChangeCategory(
                        index,
                        "productCategory",
                        e.target.value
                      )
                    }
                  />
                  <img
                    src={downArrowGrey}
                    alt="down arrow"
                    width="12px"
                    height="12px"
                    onClick={() => clearValueCategory(index, "productCategory")}
                  />
                  {showSuggestionsCategory &&
                    focusedIndexCategory === index &&
                    suggestionsCategory.length > 0 && (
                      <ul className="suggestionsList">
                        {suggestionsCategory.map((suggestion, i) => (
                          <li
                            key={i}
                            onClick={() =>
                              handleSuggestionClickCategory(index, suggestion)
                            }
                            className="suggestionItem"
                            style={{ cursor: "pointer" }}
                          >
                            {suggestion.label}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </td>
              {/* Category ends here */}
              {/* Brand Start here */}
              <td>
                <div className="searchResultInputWrapper">
                  <textarea
                    className="searchResultInput"
                    rows={1}
                    value={row.productBrand || ""}
                    onChange={(e) =>
                      handleInputChangeBrand(
                        index,
                        "productBrand",
                        e.target.value
                      )
                    }
                  />
                  <img
                    src={downArrowGrey}
                    alt="down arrow"
                    width="12px"
                    height="12px"
                    onClick={() => clearValueBrand(index, "productBrand")}
                  />
                  {showSuggestionsBrand &&
                    focusedIndexBrand === index &&
                    suggestionsBrand.length > 0 && (
                      <ul className="suggestionsList">
                        {suggestionsBrand.map((suggestion, i) => (
                          <li
                            key={i}
                            onClick={() =>
                              handleSuggestionClickBrand(index, suggestion)
                            }
                            className="suggestionItem"
                            style={{ cursor: "pointer" }}
                          >
                            {suggestion.label}
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
              </td>
              {/* Brand Ends here */}
            </tr>
          ))}
        </>
      );
    case 1:
      return (
        <>
          {searchedProducts[currentIndex] &&
            searchedProducts[currentIndex].length &&
            searchedProducts[currentIndex].map((item, index) => (
              <>
                <tr
                  key={index}
                  className={`searchresultStatusItem ${status ? "active" : ""}`}
                >
                  <td style={{ width:"5%", textAlign:"center" }}>
                    <input
                      checked={item.selected}
                      type="checkbox"
                      onChange={() =>
                        addSelectedProduct(index, item.product_id)
                      }
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.category}</td>
                  <td>{item.brand}</td>
                  <td
                    className="searchResultStatus active"
                    style={{ color: getSingleSearchStatusColor(item.status) }}
                  >
                    {getSingleSearchStatus(item.status)}
                  </td>
                </tr>
              </>
            ))}
        </>
      );
    case 2:
      return (
        <>
          {searchedProducts[currentIndex] &&
            searchedProducts[currentIndex].length &&
            searchedProducts[currentIndex].map((item, index) => (
              <>
                <tr
                  key={index}
                  className={`searchresultStatusItem ${
                    item.selected ? "active" : ""
                  }`}
                >
                  <td style={{ width: "5%", textAlign:"center" }}>
                    <input
                      checked={item.selected}
                      onChange={() =>
                        addSelectedProduct(index, item.product_id)
                      }
                      type="checkbox"
                    />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.category}</td>
                  <td>{item.brand}</td>
                  <td
                    className="searchResultStatus active"
                    style={{ color: getSingleSearchStatusColor(item.status) }}
                  >
                    {getSingleSearchStatus(item.status)}
                  </td>
                </tr>
              </>
            ))}
        </>
      );
    case 3:
      return (
        <>
          {unselectedProd.map((item, index) => (
            <tr key={`${item.product}${index}`}>
              <td style={{width: "5%", textAlign:"center"}}>
                <input
                  type="checkbox"
                  checked={item.selected}
                  onChange={() => handleSelectedProducts(index)}
                />
              </td>
              <td>{item.product}</td>
              <td>{item.category}</td>
              <td>{item.brand}</td>
            </tr>
          ))}
        </>
      );
    case 4:
      return (
        <>
          {products.map((item, index) =>
            !productIds.includes(item.product_id) ? (
              <tr
                key={`${item.product}${index}`}
                className={`searchresultStatusItem ${
                  item.selected ? "active" : ""
                }`}
              >
                <td style={{ width: "5%",textAlign:"center" }}>
                  <input
                    type="checkbox"
                    checked={item.selected}
                    onChange={() =>
                      handleSelectedProductsAddSr(index, productIdx)
                    }
                  />
                </td>
                <td style={{ width: "60%" }}>{item.name}</td>
                <td style={{ width: "15%" }}>{item.category}</td>
                <td style={{ width: "10%" }}>{item.brand}</td>
                <td
                  className="searchResultStatus active"
                  style={{
                    color: getSingleSearchStatusColor(item.status),
                    width: "10%",
                  }}
                >
                  {getSingleSearchStatus(item.status)}
                </td>
              </tr>
            ) : (
              <tr
                key={`${item.product}${index}`}
                className={`searchresultStatusItem duplicateRow`}
              >
                <td style={{ width: "5%" }}>
                  <img
                    src={InfoIcon}
                    alt="down arrow"
                    width="12px"
                    height="12px"
                    className="d-block m-auto"
                  />
                </td>
                <td style={{width: "60%" }}>{item.name}</td>
                <td style={{width: "15%" }}>
                  {item.category}
                </td>
                <td style={{width: "10%" }}>{item.brand}</td>
                <td
                  className="searchResultStatus active"
                  style={{
                    color: getSingleSearchStatusColor(item.status),
                    width: "10%",
                  }}
                >
                  {getSingleSearchStatus(item.status)}
                </td>
              </tr>
            )
          )}

          {/* if duplicate product found then code ends here */}
        </>
      );
  }
};

export default SearchResultRow;
