import Axios from "axios";
import React from "react";
import "../DashBoardWidgets/RFQ/RfqData.css";
import RfqData from "../DashBoardWidgets/RFQ/RfqData";
import constants from "../../Config";
import Daterangepicker from "./Common/Daterangepicker";
import LeadData from "../DashBoardWidgets/NewWidgets.js/LeadData";
import Propricing from "../DashBoardWidgets/NewWidgets.js/ProPricing";
import QuoteData from "../DashBoardWidgets/NewWidgets.js/QuoteData";
import Account from "../DashBoardWidgets/NewWidgets.js/Account";
import moment from "moment";
import { Button, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";

const apiUrl = constants.API_URL;
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date_from: "",
      date_to: "",
      date_from2: "",
      date_to2: "",
      date_from3: "",
      date_to3: "",
      credit_fiance_date_from:"",
      credit_fiance_date_to:"",
      //   userData_array: [],
      summary: [],
      leadsCount: 0,
      ordersCount: 0,
      quotesCount: 0,
      proPricingCount: 0,
      rfqCount: 0,
      invoiceCount: 0,
      value: 0,
      quoteValue: 0,
      invoiceValue: 0,
      funnelType: "lead",
      downloadData: [],
      downloadAceData: [],
      downloadCreditFinance:[],
      isCreditFinanceDownlaoding: false
    };
  }
  componentDidMount() {
    this.getSummary();
  }
  downloadReport = async () => {
    let result = await this.downloadListButton();
    if (result.length > 0) {
      this.setState({ downloadData: result }, () => {
        setTimeout(() => {
          this.handleExport();
          // this.csvLinkEl.current.click();
        });
      });
    }
  };
  handleExport = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(this.state.downloadData);

    if (this.state.funnelType == "lead") {
      // ws.A1.s = { fill: { patternType: "solid", fgColor: { rgb: "FFFF00" } } };
      XLSX.utils.book_append_sheet(wb, ws, "Lead-To-Order-Funnel");
      XLSX.writeFile(wb, "Lead-To-Order-Funnel.xlsx");
    }
    if (this.state.funnelType == "rfq") {
      XLSX.utils.book_append_sheet(wb, ws, "RFQ-To-Order-Funnel");
      XLSX.writeFile(wb, "RFQ-To-Order-Funnel.xlsx");
    }
  };
  funnelType = (e) => {
    this.setState({
      funnelType: e.target.value,
    });

  };
  downloadListButton = () => {
    return new Promise((resolve, reject) => {
      let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
      this.setState(
        {
          isDownloading: true,
        },
        () => {
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${loginedUserDetails.token}`,
            },
            data: {
              key: [this.state.funnelType],
              date_from: this.state.date_from2,
              date_to: this.state.date_to2,
            },
          };

          return Axios(apiUrl + "/order-funnel", options)
            .then((data) => {
              let apiArray = data.data.data;
              if (!apiArray.length) {
                window.alert("NO DATA FOUND");
              }

              this.setState(
                {
                  downloadData: apiArray,
                  isDownloading: false,
                },
                () => {
                  const dataNew =
                    this.state.downloadData &&
                    this.state.downloadData.map((item, ind) => {
                      let orderIdString = "";
                      item.orderIds.length &&
                        item.orderIds.map((id) => {
                          let text = id.toString();
                          if (
                            item.orderIds.indexOf(id) ==
                            item.orderIds.length - 1
                          ) {
                            orderIdString += text;
                          } else {
                            orderIdString += text + ",";
                          }

                          // orderIdString += text + ",";
                        });
                      if (this.state.funnelType == "lead") {
                        return {
                          "Customer ID": item.customer_id,
                          "Customer Name": item.customerName,
                          "Customer Mobile": item.customerMobile,
                          "Lead Date": item.leadDate,
                          "Lead ID": item.leadId,

                          // "Lead Mobile No.": item.mobile,
                          "Lead Source": item.lead_type,
                          "Lead Status": item.leadStatus,
                          "Lead value": item.leadValue,

                          "Lead Role": item.leadRole,
                          "Lead UTM Source": item.leadUTMSource,
                          "Lead UTM Medium": item.leadUTMMedium,
                          "Lead UTM Campaign": item.leadUTMCampaign,

                          "RFQ Date": item.rfqDate,
                          "RFQ Id": item.rfqId,
                          "RFQ Source": item.rfqType,
                          "RFQ Status": item.rfqStatus,
                          "RFQ value": item.rfqValue,
                          "RFQ Owner": item.rfqOwner,
                          "Quote Date": item.quoteDate,
                          "Quote ID": item.quote_id,
                          "Quote Type": item.quoteType,
                          "Quote Status": item.quote_status,
                          "Quote Value": item.quoteValue,
                          "Order Date": item.orderDate,
                          "Order IDs": orderIdString,
                          "Order Value": item.orderValue,
                          "Order Status": item.orderStatus,
                        };
                      }
                      if (this.state.funnelType == "rfq") {
                        return {
                          "Customer ID": item.customer_id,
                          "Customer Name": item.customerName,
                          "Customer Mobile": item.customerMobile,

                          "RFQ Date": item.rfqDate,
                          "RFQ Id": item.rfqId,
                          "RFQ Source": item.rfqType,
                          "RFQ Status": item.rfqStatus,
                          "RFQ value": item.rfqValue,
                          "RFQ Owner": item.rfqOwner,
                          "RFQ Role": item.rfqRole,
                          "RFQ UTM Source": item.rfqUTMSource,
                          "RFQ UTM Medium": item.rfqUTMMedium,
                          "RFQ UTM Campaign": item.rfqUTMCampaign,
                          "Quote Date": item.quoteDate,
                          "Quote ID": item.quote_id,
                          "Quote Type": item.quoteType,
                          "Quote Status": item.quote_status,
                          "Quote Value": item.quoteValue,
                          "Order Date": item.orderDate,
                          "Order IDs": orderIdString,
                          "Order Value": item.orderValue,
                          "Order Status": item.orderStatus,
                        };
                      }
                    });
                  resolve(dataNew);
                  this.setState({
                    downloadData: dataNew,
                  });
                }
              );
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                isDownloading: false,
              });
            });
        }
      );
    });
  };

  // credit fiance fucntion start here
   downloadListCreditFinance = () => {
    return new Promise((resolve, reject) => {
      let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
      this.setState(
        {
          isCreditFinanceDownlaoding: true,
        },
        () => {
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${loginedUserDetails.token}`,
            },
            data: {
              date_from: this.state.credit_fiance_date_from,
              date_to: this.state.credit_fiance_date_to,
            },
          };
          return Axios(apiUrl + "/credit-finance", options)
            .then((data) => {
              let apiArray = data.data.data;
              if (!apiArray.length) {
                window.alert("No Data Found");
              }
              this.setState(
                {
                  downloadCreditFinance: apiArray,
                  isCreditFinanceDownlaoding: false,
                },
                () => {
                  const dataNew =
                    this.state.downloadCreditFinance &&
                    this.state.downloadCreditFinance.map((item) => {
                      return {
                        "Name": item.name,
                        "Mobile": item.mobile,
                        "Credit Limit": item.credit_limit,
                        "GSTIN": item.gstin,
                        "Mobile Verified": item.mobile_verified,
                        "Customer Id": item.customer_id,
                        "Account Name": item.account_name,
                        "City": item.account_location,
                        "Account Mobile": item.account_mobile,
                        "Account Status": item.status,
                        "KAM": item.kam,
                        "Date": item.date ? moment.utc(item.date).format("YYYY-MM-DD HH:mm:ss") : "",
                      };
                    });
                  resolve(dataNew);
                  this.setState({
                    downloadCreditFinance: dataNew,
                  });
                }
              );
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                isCreditFinanceDownlaoding: false,
              });
              reject("Error occurred while fetching data"); 
            });
        }
      );
    });
  };

  handleExportCreditFinance = () => {
    var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(this.state.downloadCreditFinance);

    XLSX.utils.book_append_sheet(wb, ws, "Credit-Finance");
    XLSX.writeFile(wb, "Credit-Finance.xlsx");
  };

  downloadCreditFinanceExcelSheet = async () => {
    let result = await this.downloadListCreditFinance();
    if (result.length > 0) {
      this.setState({ downloadCreditFinance: result }, () => {
        setTimeout(() => {
          this.handleExportCreditFinance();
        });
      });
    }    
  }
  // credit fianance fucntion ends here

  // ace reflect downlaod report function start here
  downloadReportAceRegistration = async () => {
    let result = await this.downloadListButtonAceRegistration();
    if (result.length > 0) {
      this.setState({ downloadAceData: result }, () => {
        setTimeout(() => {
          this.handleExportAceRegistration();
          // this.csvLinkEl.current.click();
        });
      });
    }
  };
  // ace reflect downlaod report function ends here

  // ace reflect export function start here
  handleExportAceRegistration = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(this.state.downloadAceData);

    XLSX.utils.book_append_sheet(wb, ws, "Event-Registrations");
    XLSX.writeFile(wb, "Event-Registrations.xlsx");
  };
  // ace reflect export function ends here

  // downlaod ace reflect registration function start here
  downloadListButtonAceRegistration = () => {
    return new Promise((resolve, reject) => {
      let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
      this.setState(
        {
          isAceDownloading: true,
        },
        () => {
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${loginedUserDetails.token}`,
            },
            data: {
              date_from: this.state.date_from3,
              date_to: this.state.date_to3,
            },
          };
          //event-registrations
          return Axios(apiUrl + "/webinar-registrations", options)
            .then((data) => {
              let apiArray = data.data.data;
              if (!apiArray.length) {
                window.alert("NO DATA FOUND");
              }

              this.setState(
                {
                  downloadAceData: apiArray,
                  isAceDownloading: false,
                },
                () => {
                  const dataNew =
                    this.state.downloadAceData &&
                    this.state.downloadAceData.map((item) => {
                      return {
                        "Company Name": item.company_name,
                        "Email": item.email,
                        "Mobile": item.mobile,
                        "Name": item.name,
                        "Registration Date": item.registration_date,
                        "Source": item.source,
                        "Source Url": item.source_url,
                        "UTM Campaign": item.utm_campaign,
                        "UTM Content": item.utm_content,
                        "UTM Medium": item.utm_medium,
                        "UTM Source": item.utm_source,
                        "UTM Term": item.utm_term,
                        // ends item data here
                      };
                    });
                  resolve(dataNew);
                  this.setState({
                    downloadAceData: dataNew,
                  });
                }
              );
            })
            .catch((err) => {
              console.log(err);
              this.setState({
                isAceDownloading: false,
              });
            });
        }
      );
    });
  };
  // downlaod ace reflect registration function ends here
  getSummary = async () => {
    let loginedUserDetails = JSON.parse(localStorage.getItem("userData"));
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loginedUserDetails.token}`,
      },
      data: {
        key: "",
        date_from: this.state.date_from,
        date_to: this.state.date_to,
      },
    };
    try {
      const data = await Axios(apiUrl + "/widget-info", options);
      let summary = data.data.data;
      let value = summary[0].value;
      let quoteOrderSum = summary[0].quoteOrderSum;
      let invoiceOrderSum = summary[0].invoiceValue
      let number = Math.trunc(value);
      let number2 = Math.trunc(quoteOrderSum);
      let number3 = Math.trunc(invoiceOrderSum);
      let truncateNumber = number.toLocaleString("en-IN");
      let truncateNumber2 = number2.toLocaleString("en-IN");
      let truncateNumber3 = number3.toLocaleString("en-IN");
      this.setState({
        leadsCount: summary[0].totalLeads,
        ordersCount: summary[0].totalOrders,
        quotesCount: summary[0].totalQuotes,
        proPricingCount: summary[0].totalProPricings,
        rfqCount: summary[0].totalRFQs,
        invoiceCount: summary[0].invoiceCount,
        value: truncateNumber,
        quoteValue: truncateNumber2,
        invoiceValue: truncateNumber3
      });
    } catch (error) {
      return console.log(error);
    }
  };

  render() {
    const setDateRange = (data) => {
      this.setState(
        {
          date_from: data.start,
          date_to: data.end,
        },
        () => {
          this.getSummary();
        }
      );
    };
    const setDateRange2 = (data) => {
      this.setState({
        date_from2: data.start,
        date_to2: data.end,
      });
    };
    const setDateRange3 = (data) => {
      this.setState({
        date_from3: data.start,
        date_to3: data.end
      })
    }
    const creditFinanceDataRange = (data) => {
       this.setState({
        credit_fiance_date_from: data.start,
        credit_fiance_date_to: data.end,
       })
    } 
    return (
      <div>
        <div className="row mb-2 justify-content-between align-items-center">
          <div className="col-4 col-md-3">
            <h4 className="heading-title-dashoad mb-0">
              <b className="ace_ref">Summary</b>
            </h4>
          </div>
          <div className="col-8 col-md-3 ">
            <div
              style={{
                width: "70%",
                background: "#fff",
                cursor: "pointer",
                border: "1px solid #ccc",
                padding: "6px 10px 5px 17px",
                marginLeft: "90px",
              }}
              className="date-picker-summery"
            >
              <Daterangepicker
                defaultDays="0"
                setDateRange={setDateRange}
              ></Daterangepicker>
            </div>
          </div>
        </div>
        <div className="row clearfix justify-content-around py-3 px_40" style={{backgroundColor:"#FFF"}}>
          <div className="col-lg-2">
            <a href="#proPricing" className="text-decoration-none">
              <div className="pop-out widget my-widget-card">
                <div className="widget-body">
                  <div className="widget-inner-wrapper">
                    <h6 className="sub-heading">Pro Request</h6>
                    <h2 className="main-heading">{this.state.proPricingCount}</h2>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-2">
            <a href="#lead" className="text-decoration-none">
              <div className="pop-out widget my-widget-card my-widget-card-leads">
                <div className="widget-body">
                  <div className="widget-inner-wrapper">
                    <h6 className="sub-heading">Leads</h6>
                    <h2 className="main-heading">{this.state.leadsCount}</h2>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-1">
            <a href="#rfq" className="text-decoration-none" id="boxes">
              <div className="pop-out widget my-widget-card my-widget-card-rfq">
                <div className="widget-body">
                  <div className="widget-inner-wrapper">
                    <h6 className="sub-heading">RFQ’s</h6>
                    <h2 className="main-heading">{this.state.rfqCount}</h2>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-3">
            <a href="#quote" className="pop-out text-decoration-none">
              <div className="pop-out widget my-widget-card my-widget-card-quotes">
                <div className="widget-body justify-content-center">
                  <div className="widget-inner-wrapper">
                    <h6 className="sub-heading text-center">Quote Value</h6>
                    <div className="align-widget-value">
                      <h2 className="main-heading">{this.state.quotesCount}&nbsp;&nbsp;</h2>
                      <h6 className="main-heading quote-value"><span className="rupees_icon">₹</span>{this.state.quoteValue}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-lg-4">
            <div className="pop-out widget my-widget-card my-widget-card-value">
              <div className="widget-body">
                <div className="widget-inner-wrapper">
                  <h6 className="sub-heading sub-heading-value">Order Value (excl. GST)</h6>
                  <div className="widget-value-cnt">
                    <div>
                      <h6 className="sub-heading">Order Value</h6>
                      <div className="align-widget-value">
                      <h2 className="main-heading">{this.state.ordersCount}&nbsp;&nbsp;</h2>
                      <h6 className="main-heading order-invoice-value"><span className="rupees_icon">₹</span>{this.state.value}</h6>
                      </div>
                    </div>
                    <hr className="value-seprator" />
                    <div>
                      <h6 className="sub-heading">Invoiced Order</h6>
                      <div className="align-widget-value">
                      <h2 className="main-heading">{this.state.invoiceCount}&nbsp;&nbsp;</h2>
                      <h6 className="main-heading order-invoice-value"><span className="rupees_icon">₹</span>{this.state.invoiceValue}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* Credit Finance div start here */}
        <h4 className="heading-title-dashoad pt-3"><b className="ace_ref">Credit Finance</b></h4>
        <div className="funnel d-flex my-3  py-3  border  justify-content-start">
          <div className="col-7 col-md-7">
            <div className="funnel_ace_reflect"
              style={{
                width: "50%",
                background: "#fff",
                cursor: "pointer",
                border: "1px solid #ccc",
                padding: "6px 10px 5px 17px",
                marginLeft: "140px",
              }}
            >
              <Daterangepicker
                defaultDays={moment().diff(moment().startOf("month"), "days")}
                setDateRange={creditFinanceDataRange}
              ></Daterangepicker>
            </div>
          </div>
          <div className="col-5 col-md-5">
            <Button onClick={this.downloadCreditFinanceExcelSheet}>
              {this.state.isCreditFinanceDownlaoding ? (
                <Spinner animation="border" />
              ) : (
                <span>
                  <FontAwesomeIcon className="editicon" icon="file-download" />{" "}
                  Export To Excel
                </span>
              )}
            </Button>
          </div>
        </div>
        {/* Credit Finance div ends here */}

        {/* Ace Reflect Registration component start here */}
        <h4 className="heading-title-dashoad pt-2"><b className="ace_ref">Webinar Registration</b></h4>
        <div className="funnel d-flex my-3  py-3  border  justify-content-start">
          <div className="col-7 col-md-7">
            <div className="funnel_ace_reflect"
              style={{
                width: "50%",
                background: "#fff",
                cursor: "pointer",
                border: "1px solid #ccc",
                padding: "6px 10px 5px 17px",
                marginLeft: "140px",
              }}
            >
              <Daterangepicker
                defaultDays={moment().diff(moment().startOf("month"), "days")}
                setDateRange={setDateRange3}
              ></Daterangepicker>
            </div>
          </div>
          <div className="col-5 col-md-5">
            <Button onClick={this.downloadReportAceRegistration}>
              {this.state.isAceDownloading > 0 ? (
                <Spinner animation="border" />
              ) : (
                <span>
                  <FontAwesomeIcon className="editicon" icon="file-download" />{" "}
                  Export To Excel
                </span>
              )}
            </Button>
          </div>
        </div>
        {/* Ace Reflect Registration component ends here */}
        <h4 className="heading-title-dashoad"><b>Lead/RFQ Funnel</b></h4>
        <div className="funnel d-flex my-3  py-3  border  justify-content-around">
          <div className="col-4 col-md-4">
            <div className="funnel_ace_reflect"
              style={{
                width: "70%",
                background: "#fff",
                cursor: "pointer",
                border: "1px solid #ccc",
                padding: "6px 10px 5px 17px",
                marginLeft: "90px",
              }}
            >
              <Daterangepicker
                defaultDays={moment().diff(moment().startOf("month"), "days")}
                setDateRange={setDateRange2}
              ></Daterangepicker>
            </div>
          </div>
          <div className="col-4 col-md-4">
            <Form.Control as="select" custom onChange={this.funnelType}>
              <option value="lead">Download Lead to Order Funnel</option>
              <option value="rfq">Download RFQ to Order Funnel</option>
            </Form.Control>
          </div>
          <div className="col-4 col-md-4">
            <Button onClick={this.downloadReport}>
              {this.state.isDownloading > 0 ? (
                <Spinner animation="border" />
              ) : (
                <span>
                  <FontAwesomeIcon className="editicon" icon="file-download" />{" "}
                  Export To Excel
                </span>
              )}
            </Button>
          </div>
        </div>
        <div id="rfq">
          <RfqData />
        </div>
        <div id="lead">
          <LeadData />
        </div>
        <div id="proPricing">
          <Propricing />
        </div>
        <div id="quote">
          <QuoteData />
        </div>
        <div>
          <Account />
        </div>
        <div className="fixed-bottom me-5" style={{ height: "60px" }}>
          <Button
            className="bg-warning border rounded-circle"
            style={{
              maxWidth: "4rem",
              background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,85,121,1) 32%, rgba(0,212,255,1) 100%)"
            }}
            id="scroll"
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

            }
          >
            Top
            <FontAwesomeIcon className="arrowIcon" icon="arrow-up" />
          </Button>
        </div>
      </div>
    );
  }
}

export default Home;
