import React from "react";
import Modal from "react-bootstrap/Modal";
import "./AdvanceSearch.css";
import crossBlack from "../../../asset/Images/crossBlack.svg";

import SingleSearch from "./SingleSearch";
import MultipleSearch from "./MultipleSearch";

const AdvanceSearchModal = (props) => {
  const {
    showAdvanceSearchModal,
    showMultipleSearchResultModal,
    multipleSearchValue,
    serachErrorMessage,
    searchSuccessMessage,
    multipleSearchLoading,
    customerId,
    productSingleSearchValue,
    brandSingleSearchValue,
    categorySingleSearchValue,
    categorySuggestionsSingleSearch,
    brandSuggestionsSingleSearch,
    submitLoadingSingleSearch,
    errorMessageSingleSearch,
    showCategorySuggestions,
    showBrandSuggestions,
    handleProceed,
    handleCloseAdvanceSearchModal,
    handleChangeMultipleSerach,
    handleOpenCancelSRModal,
    getAddNewBrandSingleSearch,
    getAddNewCategorySingleSearch,
    handleChangeSingleSearch,
    handleSearch,
    handleFileSearchUpload,
    uploadSearchErrorMessage,
    uploadSearchSuccessMessage,
    submitUploadSearchLoading,
    stateData,
    setStateData
  } = props;
  return (
    <>
      <Modal show={showAdvanceSearchModal} className="advanceSearchModal">
        <Modal.Header
          className="advanceSearchHeader"
          style={{ marginBottom: "8px" }}
        >
          <Modal.Title>Advanced Search</Modal.Title>
          <button
            className="searchModalCloseBtn"
            onClick={handleOpenCancelSRModal}
          >
            <img src={crossBlack} alt="cross icon" width="16px" height="16px" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <SingleSearch 
            customerId={customerId}
            productSingleSearchValue={productSingleSearchValue}
            brandSingleSearchValue={brandSingleSearchValue}
            categorySingleSearchValue={categorySingleSearchValue}
            categorySuggestionsSingleSearch={categorySuggestionsSingleSearch}
            brandSuggestionsSingleSearch={brandSuggestionsSingleSearch}
            submitLoadingSingleSearch={submitLoadingSingleSearch}
            errorMessageSingleSearch={errorMessageSingleSearch}
            showCategorySuggestions={showCategorySuggestions} 
            showBrandSuggestions={showBrandSuggestions}
            handleCloseAdvanceSearchModal={handleCloseAdvanceSearchModal}
            getAddNewBrandSingleSearch={getAddNewBrandSingleSearch}
            getAddNewCategorySingleSearch={getAddNewCategorySingleSearch}
            handleChangeSingleSearch={handleChangeSingleSearch}
            handleSearch={handleSearch}
            stateData = {stateData}
            setStateData={setStateData}
            />
          <MultipleSearch
            showMultipleSearchResultModal={showMultipleSearchResultModal}
            multipleSearchValue={multipleSearchValue}
            serachErrorMessage={serachErrorMessage}
            searchSuccessMessage={searchSuccessMessage}
            multipleSearchLoading={multipleSearchLoading}
            handleFileSearchUpload={handleFileSearchUpload}
            uploadSearchErrorMessage={uploadSearchErrorMessage}
            uploadSearchSuccessMessage={uploadSearchSuccessMessage}
            submitUploadSearchLoading={submitUploadSearchLoading}
            handleProceed={handleProceed}
            handleChangeMultipleSerach={handleChangeMultipleSerach}
            handleOpenCancelSRModal={handleOpenCancelSRModal}
            stateData = {stateData}
            setStateData={setStateData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdvanceSearchModal;
