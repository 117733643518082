import React from 'react'
import Nodatafound from '../../../asset/Images/nodatafound.svg'

const NoProductFound = ({heading,subHeading}) => {
  return (
    <div className='noImageFoundCnt'>
        <div className='noImageFoundWrapper'>
          <img src={Nodatafound} alt='no produt found icon' width="360px"/>
          <h3 className='noDataHeading'>{heading}</h3>
          <p className='noDataSubHeading'>{subHeading}</p>
        </div>
    </div>
  )
}

export default NoProductFound