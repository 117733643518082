import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import crossBlack from "../../../asset/Images/crossBlack.svg";

const CancelSRModal = (props) => {
  const { showCancelSRModal, handleCloseCancelSRModal } = props;

  useEffect(() => {
    const backdrop = document.getElementsByClassName("modal-backdrop")[0];
    const canceSrModal = document.getElementsByClassName("cancelSrModal")[0];
    if (backdrop) {
      if (showCancelSRModal) {
        backdrop.style.zIndex = "1060";
        canceSrModal.style.zIndex = "1070";
      } else {
        backdrop.style.zIndex = "1040";
        canceSrModal.style.zIndex = "1050";
      }
    }
  }, [showCancelSRModal]);
  return (
    <>
      <Modal centered show={showCancelSRModal} className="cancelSrModal">
        <Modal.Header className="cancelSrHeaderCnt">
          <div className="cancelSrHeaderCntWrapper">
            <Modal.Title>
              <span>Are you sure</span>
            </Modal.Title>
            <button
              className="searchModalCloseBtn"
              onClick={handleCloseCancelSRModal}
            >
              <img
                src={crossBlack}
                alt="cross icon"
                width="16px"
                height="16px"
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className="cancelSrMsg">
            Your progress will be lost if you exit now. Do you want to continue?
          </p>
        </Modal.Body>
        <Modal.Footer className="searchResultFooterModal">
          <div className="searchResultFooterBtnCnt">
            <button
              className="footerbackBtn"
              type="button"
              onClick={() => window.location.reload()}
            >
              Exit Anyway
            </button>
            <button
              className="footerSearchbtn"
              type="button"
              onClick={handleCloseCancelSRModal}
            >
              Stay on Page
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelSRModal;
