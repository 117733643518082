import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import crossBlack from "../../../asset/Images/crossBlack.svg";
import InfoIcon from "../../../asset/Images/Info-icon.svg";
import SearchResultRow from "./SearchResultRow";
import NoProductFound from "./NoProductFound";
import ErrorHandling from "./ErrorHandling";
import { Spinner } from "react-bootstrap";

const AddSRModal = (props) => {
  const {
    showAddSRModal,
    handleCloseAddSRModal,
    handleOpenCancelSRModal,
    handleOpenAdvanceSearchResultModal,
    searchData,
    stateData,
    setStateData,
    setSearchData,
    setCurrentIndex,
    addedProducts,
    setAddedProducts,
    addToSRProductSubmit,
    addSrErrorMessage,
    addSrSuccessMessage,
    submitAddSRLoading,
    unselectedProd,
    handleCloseNoProductFoundModal,
    handleOpenNoProductFoundModal,
    handleSelectedProductsAddSr,
    products,
  } = props;

  const [selectedProduct, SetSelectedProduct] = useState(0);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [addedProd, setAddedProd] = useState(false)
  useEffect(() => {
    let value = 0;
    addedProducts.forEach((item, index) => {
      if (item.products && item.products.length) {
        value += 1;
      }
    });

    let productIds = products.map((item) => item.product_id);
    let checkDuplicate = false;
    let productAdd = [];
    for (let val of addedProducts) {
      if (val.products.length > 0) {
        for (let prod of val.products) {
          if (prod.selected) {
            productAdd.push(prod.product_id);
          }
        }
      }
    }
    let productExist = false;
    for (let val of productAdd) {
      if (productIds.includes(val)) {
        checkDuplicate = true;
      }else{
        productExist = true
      }
    }
    setAddedProd(productExist);
    setIsDuplicate(checkDuplicate);
    SetSelectedProduct(value);
  }, []);

  const handleBackSrButton = () => {
    if (unselectedProd && unselectedProd.length > 0) {
      handleCloseAddSRModal();
      handleOpenNoProductFoundModal();
      return;
    } else {
      handleCloseAddSRModal();
      let current = {};
      if (stateData && stateData.length > 1) {
        current = stateData[stateData.length - 1]; // Correct index calculation
      }
      const updatedIndex = searchData.length - 1;
      if (updatedIndex < 0) {
        return; // Prevent further execution
      }
      if (
        current &&
        Object.keys(current).length > 0 &&
        current.type === "multiple"
      ) {
        const updatedSearchData = searchData.map((prod, index) => {
          if (index === current.index) {
            return {
              ...prod,
              active: true,
            };
          } else {
            return {
              ...prod,
              active: false,
            };
          }
        });
        const filteredData = stateData.filter(
          (_, index) => index !== stateData.length - 1
        );
        setCurrentIndex(updatedIndex);
        setStateData(filteredData);
        setSearchData(updatedSearchData);
      }
      handleOpenAdvanceSearchResultModal();
      return;
    }
  };

  return (
    <>
      <Modal show={showAddSRModal} className="advanceSearchModal addSrModal">
        {addSrSuccessMessage ? (
          <ErrorHandling
            message={addSrSuccessMessage}
            type={"SuccessMessage"}
          />
        ) : null}
        {addSrErrorMessage ? (
          <ErrorHandling message={addSrErrorMessage} type={"ErrorMessage"} />
        ) : null}
        <Modal.Header className="advanceSearchHeader multipleSearchHeaderCnt">
          <div className="multipleSearchHeader">
            <Modal.Title>
              <span>Add to SR</span>
            </Modal.Title>
            <button
              className="searchModalCloseBtn"
              onClick={handleOpenCancelSRModal}
            >
              <img
                src={crossBlack}
                alt="cross icon"
                width="16px"
                height="16px"
              />
            </button>
          </div>
          <hr className="header-seprator" />
        </Modal.Header>
        <Modal.Body>
          {addedProducts &&
          addedProducts.length &&
          addedProducts.some(
            (item) =>
              item.products && item.products.some((product) => product.selected)
          ) ? (
            <>
              {addedProducts
                .filter((item) => item.products && item.products.length > 0)
                .map((item, index) => {
                  return (
                    <div key={item.index}>
                      <div>
                        <h4 className="srModalheading">
                          {searchData[item.index].product}
                        </h4>
                        <div className="d-flex">
                          {searchData[item.index].brand ? (
                            <div
                              className="srModalSubheadingCnt"
                              style={{ marginRight: "16px" }}
                            >
                              <p className="srModalSubheading">
                                Brand&nbsp;:&nbsp;
                              </p>
                              <p className="srModalSubheading">
                                {searchData[item.index].brand}
                              </p>
                            </div>
                          ) : null}

                          {searchData[item.index].category ? (
                            <div className="srModalSubheadingCnt">
                              <p className="srModalSubheading">
                                Category&nbsp;:&nbsp;
                              </p>
                              <p className="srModalSubheading">
                                {searchData[item.index].category}
                              </p>
                            </div>
                          ) : null}
                        </div>
                        <table className="searchResulttable">
                          <tbody>
                            <SearchResultRow
                              type={4}
                              status={true}
                              products={item.products}
                              productIdx={item.index}
                              handleSelectedProductsAddSr={
                                handleSelectedProductsAddSr
                              }
                              productIds={products.map(
                                (item) => item.product_id
                              )}
                            />
                          </tbody>
                        </table>
                      </div>
                      {selectedProduct - 1 > index ? (
                        <hr className="hr-dotted" />
                      ) : null}
                    </div>
                  );
                })}
            </>
          ) : (
            <NoProductFound
              heading="You Haven’t Selected Any Products!"
              subHeading="Go back and pick at least one item to add to your SR. Let’s get started!"
            />
          )}
        </Modal.Body>
        <Modal.Footer className="searchResultFooterModal d-flex align-items-center justify-content-between">
          <div className="searchResultFooterCnt">
            {isDuplicate ? (
              <div className="duplicateProductMsg">
                <img
                  src={InfoIcon}
                  alt="info icon"
                  width="16px"
                  height="16px"
                />
                <p>Products already in the SR.</p>
              </div>
            ) : null}
          </div>
          <div className="searchResultFooterBtnCnt">
            <button className="footerbackBtn" onClick={handleBackSrButton}>
              Back
            </button>
            <button
              onClick={addToSRProductSubmit}
              className={`footerSearchbtn ${
                !( 
                  addedProducts &&
                  addedProducts.length &&
                  addedProducts.some(
                    (item) =>
                      item.products &&
                      item.products.some((product) => product.selected)
                  )
                ) || !addedProd
                  ? "disabled"
                  : ""
              }`}
              disabled={
                !(
                  addedProducts &&
                  addedProducts.length &&
                  addedProducts.some(
                    (item) =>
                      item.products &&
                      item.products.some((product) => product.selected)
                  )
                ) || !addedProd 
              }
            >
              {submitAddSRLoading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Add to SR"
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddSRModal;
