import React, { useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import UploadSearch from "./UploadSearch";
import ErrorHandling from "./ErrorHandling";
import MultipleProductSearch from "./MultipleProductSearch";

const MultipleSearch = (props) => {
  const {
    showMultipleSearchResultModal,
    multipleSearchValue,
    serachErrorMessage,
    searchSuccessMessage,
    multipleSearchLoading,
    handleProceed,
    handleChangeMultipleSerach,
    handleOpenCancelSRModal,
    handleFileSearchUpload,
    uploadSearchErrorMessage,
    uploadSearchSuccessMessage,
    submitUploadSearchLoading,
    errorMessageMultipleSearch,
  } = props;

  const lineNumbersRef = useRef(null);
  const textAreaRef = useRef(null);

  const syncScroll = (source, target) => {
    if (source.current && target.current) {
      target.current.scrollTop = source.current.scrollTop;
    }
  };

  useEffect(() => {
    const lineNumbers = lineNumbersRef.current;
    const textArea = textAreaRef.current;

    const handleLineNumbersScroll = () =>
      syncScroll(lineNumbersRef, textAreaRef);
    const handleTextareaScroll = () => syncScroll(textAreaRef, lineNumbersRef);

    if (lineNumbers)
      lineNumbers.addEventListener("scroll", handleLineNumbersScroll);
    if (textArea) textArea.addEventListener("scroll", handleTextareaScroll);

    return () => {
      if (lineNumbers)
        lineNumbers.removeEventListener("scroll", handleLineNumbersScroll);
      if (textArea)
        textArea.removeEventListener("scroll", handleTextareaScroll);
    };
  }, []);

  return (
    <>
      <div>
        {searchSuccessMessage ? (
          <ErrorHandling
            message={searchSuccessMessage}
            type={"SuccessMessage"}
          />
        ) : null}
        {serachErrorMessage ? (
          <ErrorHandling message={serachErrorMessage} type={"ErrorMessage"} />
        ) : null}
        <div className="advanceSearchSepratorCnt">
          <hr className="advanceSearchSeprator" />
        </div>
        <div>
          <h5 className="advanceSearchTilte">Multiple Product Search</h5>
          <div className="multipleSearchCnt">
            <div className="searchMultipleTextCnt">
              {/* <div className="textarea-container"> */}
              {/* Line Numbers */}
              <div className="line-numbers" ref={lineNumbersRef}>
                {Array.from(
                  { length: multipleSearchValue.split("\n").length },
                  (_, i) => i + 1
                ).map((number) => (
                  <div key={number} className="line-number">
                    {number}
                  </div>
                ))}
              </div>
              <textarea
                ref={textAreaRef}
                className="textarea-with-numbers"
                value={multipleSearchValue}
                onChange={handleChangeMultipleSerach}
              />
              <button
                type="button"
                onClick={handleProceed}
                disabled={multipleSearchLoading || !multipleSearchValue}
                className={`searchProceedBtn ${
                  !multipleSearchValue ? "cursorNotAllowed" : ""
                }`}
              >
                {multipleSearchLoading ? (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  "Proceed"
                )}
              </button>
            </div>
            <span className="orTextSecond">or</span>
            <UploadSearch
              handleFileSearchUpload={handleFileSearchUpload}
              uploadSearchErrorMessage={uploadSearchErrorMessage}
              uploadSearchSuccessMessage={uploadSearchSuccessMessage}
              submitUploadSearchLoading={submitUploadSearchLoading}
            />
            {showMultipleSearchResultModal ? (
              <MultipleProductSearch
                displayMultipleSearchValue={multipleSearchValue}
                handleOpenCancelSRModal={handleOpenCancelSRModal}
                multipleSearchLoading={multipleSearchLoading}
                errorMessageMultipleSearch={errorMessageMultipleSearch}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipleSearch;
