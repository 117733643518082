import React from "react";
import "../SourcingUpload/sourcing_upload.css";
import ErrorMessage from '../../../asset/Images/crossError.svg';
import SuccessMessage from "../../../asset/Images/tickSuccess.svg";

const ErrorHandling = ({ message, type }) => {
  return (
    <>
      {type === "ErrorMessage" ? (
        <div className="tost-wrapper">
          <img src={ErrorMessage} alt="error icon" />
          <p className="content">{message}</p>
        </div>
      ) : null}
      {type === "SuccessMessage" ? (
        <div className="tost-wrapper">
          <img src={SuccessMessage} alt="success icon" />
          <p className="content">{message}</p>
        </div>
      ) : null}
    </>
  );
};
export default ErrorHandling;
