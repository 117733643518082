import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import cross from "../../../asset/Images/cross.svg";
import rupees from "../../../asset/Images/rupees.svg";
import "../../../asset/css/helvetica.css";
import "./creditFinance.css";
// Show Error & Success Message Component
import ErrorHandling from "../../ErrorHandling/ShowMessage";

const CreditFinance = (props) => {
  const {
    open,
    activeTab,
    data,
    isCreditLoading,
    allowedSlugs,
    errorMessage,
    successMessage,
    handleClose,
    handleInputChange,
    handleCheckboxChange,
    handleSaveCredit,
    getBusinessId,
    fetchCreditFinanceData
  } = props;
  
  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modal-right creditFinanceModal"
      >
        <Modal.Header className="creditFinanceModalTabHeading">
          <h4 className="creditFinanceModalTitle">Credit Finance Terms</h4>
          <button className="creditFinanceCloseBtn" onClick={handleClose}>
            <img src={cross} alt="cross icon" width="24px" height="24px" />
          </button>
          <div className="modalTabCnt">
            {Object.keys(data).map((tab) => (
              <button
                key={tab}
                type="button"
                className={`modalTabItem ${activeTab === tab ? "active" : ""}`}
                onClick={() => fetchCreditFinanceData(getBusinessId, tab)}
              >
                <span>{tab}</span>
                <span
                  className={
                    data[tab].tableData.filter((item) => item.selected).length >
                    0
                      ? "totalItem"
                      : null
                  }
                >
                  {data[tab].tableData.filter((item) => item.selected).length >
                  0
                    ? data[tab].tableData.filter((item) => item.selected).length
                    : null}
                </span>
              </button>
            ))}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="creditFinanceModalSearchCnt">
            <label for="">Credit Limit for {activeTab}</label>
            <div className="creditFinanceModalSearchWrapper">
              <input
                type="number"
                placeholder="Please enter your credit Limit"
                value={(data[activeTab] && data[activeTab].creditLimit) || ""}
                onChange={(e) => handleInputChange(e.target.value)}
                disabled={!allowedSlugs.includes("approve")}
              />
              <img
                className="searchRupeesicon"
                src={rupees}
                width="10px"
                height="11px"
              />
            </div>
          </div>
          <div className="creditFinanceModalTableCnt">
            <table className="creditFinanceModalTable">
              <thead>
                <tr>
                  <th></th>
                  <th>Days</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {data[activeTab] &&
                  data[activeTab]?.tableData.map((item) => (
                    <tr key={item.id}>
                      <td className={`${!allowedSlugs.includes("approve") ? "checkboxTd customCheckbox disabled":"checkboxTd customCheckbox"}`} >
                        <input
                          type="checkbox"
                          checked={item.selected}
                          onChange={() => handleCheckboxChange(item.id)}
                          disabled={!allowedSlugs.includes("approve")}
                          className={`${!allowedSlugs.includes("approve") ? "disabled":""}`}
                        />
                        <label></label>
                      </td>
                      <td>{item.days}</td>
                      <td>{item.interest}%</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {successMessage ? (
            <ErrorHandling message={successMessage} type={"SuccessMessage"} />
          ) : null}
          {errorMessage ? (
            <ErrorHandling message={errorMessage} type={"ErrorMessage"} />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          {allowedSlugs.includes("approve") ? (
            <button
              className= {`${!data[activeTab]?.creditLimit ? "disabled" : ''} credittemBtn`}
              onClick={handleSaveCredit}
               disabled={!data[activeTab]?.creditLimit}
            >
              {isCreditLoading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                `Save for ${activeTab}`
              )}
            </button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreditFinance;
