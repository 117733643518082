import React from "react";
import { Spinner } from "react-bootstrap";
import uploadIconMultiple from "../../../asset/Images/uploadIconMultiple.svg";
import ErrorHandling from "./ErrorHandling";

const UploadSearch = (props) => {
  const { 
    uploadSearchSuccessMessage,
    uploadSearchErrorMessage,
    handleFileSearchUpload,
    submitUploadSearchLoading,
   } = props;

  return (
    <>
      {uploadSearchSuccessMessage ? (
        <ErrorHandling message={uploadSearchSuccessMessage} type={"SuccessMessage"} />
      ) : null}
      {uploadSearchErrorMessage ? (
        <ErrorHandling message={uploadSearchErrorMessage} type={"ErrorMessage"} />
      ) : null}
      

      <form className="serachTypeuploadCnt" style={{ position:"relative" }}>
      {submitUploadSearchLoading && 
        <div
        style={{ position:"absolute", top:"50%", left:0, right:0, textAlign:"center" }}
        >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        </div>
      }
        <input
          type="file"
          id="identity"
          className="d-none"
          accept=".xls,.xlsx"
          onChange={handleFileSearchUpload}
          multiple={false}
        />
        <label for="identity">
          <img
            src={uploadIconMultiple}
            alt="upload icon"
            width="245px"
            height="270px"
          />
        </label>
      </form>
    </>
  );
};

export default UploadSearch;
